body {
  margin: 0;
  font-family: 'Open Sans', arial, Helvetica, sans-serif;
  color: #676767;
  background: #F7F7F7;
}
.header{
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  background: #BFBFBF;
  .logo{
    padding: 10px;
    &.flex{
      align-items: center;
      justify-content: center;
      align-content: stretch;
    }
    span{
      display: none;
    }
    .tag{
      border-left: 2px solid #fff;
      padding: 20px 5px 20px 20px;
      margin-left: 20px;
      font-weight: bold;
      font-size: 13px;
    &.col-2{
      align-self: center;
    }
    }
  }
}

.tag{
  color: #fff;
}
.nav{
  background: #fff;
  border-bottom: 1px solid #E10022;
  font-family: "Open Sans Condensed",sans-serif;
}
.content{
  width: 100%;
  p{
    padding: 5px 0;
  }
  ul{
    list-style: disc;
    padding: 0px 40px;
    li{
      margin: 7px 0;
    }
  }
}
.announce{
  width: 100%;
  max-width: 940px;
  margin: 40px auto 60px auto;
  background: rgba(20, 39, 84, 0.5) none repeat scroll 0% 0%;
  color: #fff;
  h1{
    font-size: 32px;
    margin: 0;
  }
  h3{
    font-size: 17px;
    margin: 0 0 10px 0;
  }
  ul{
    font-size: 13px;
    padding: 16px 0 0px 19px;
    list-style-position: inside;
    list-style-type: disc;
  }
  ul li{
    font-size: 13px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .col-2{
    padding: 20px 0 20px 30px;
  }
  .watch{
    display: block;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    transition: all 0.5s ease;
    width: 109px;
    margin: 0 auto;
    text-align: center;
    font-size: 13px;
    .icon{
      width: 90px;
      height: 91px;
      display: block;
      background: url("../img/video_play.png") top left;
      color: #fff;
      text-decoration: none;
      clear: both;
      margin: 10px;
      &:hover{
        background: url("../img/video_play.png") bottom left;
      }
    }
    span{
      clear: both;
      display: block;
    }
    &:hover{
      color: #abafbc;
    }
  }
}

a{
  color: #E10022;
  text-decoration: underline;
  transition: all 0.5s ease;
  &:hover{
    text-decoration: none;
  }
}

.container{
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  padding-bottom: 50px;
  background: #fff;
  h2{
    font-size: 20px;
  }
  p{
    font-size: 14px;
    line-height: 1.6em;
  }
  .tab.gr{
    margin-top: -40px;
    background: #E1E1E1;
    display: inline-block;
    padding: 15px 30px;
    float: right;
  }
}

.container-none{
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  padding-bottom: 50px;
  h2{
    font-size: 20px;
  }
  p{
    font-size: 14px;
    line-height: 1.6em;
  }
}

.button{
  color: #fff;
  text-decoration: none;
  background: #000;
  font-weight: bold;
  margin: 10px;
  padding: 15px 30px;
  display: inline-block;
  border-radius: 10px;
  transition: all 0.5s ease;
  color: #fff;
  text-decoration: none;
  &.red{
    background: #e10022;
  }
  &.dark{
    background: #7a7a7a;
  }
  &.blue{
    background: #2b416f;
  }
  &.lightg{
    background: #7A7A7A;
  }
  &:hover{
    background: #2E3030;
  }
  &.sm{
    font-size: 12px;
    padding: 10px 20px;
  }
}

.footer{
  with: 100%;
  max-width: 920px;
  margin: 0 auto 0 auto;
  border-top: 10px solid #E1E1E1;
  padding: 40px 10px 20px 10px;
  font-size: 11px;
  .eandt a{
    text-decoration: none;
    margin: 0px 15px 0 2px;
    color: #676767;
  }
  .social{
    text-align: right;
    a{
      transition: none;
    }
    .twitter{
      background: url(../img/twitter.gif) top no-repeat;
    }
    .facebook{
      background: url(../img/facebook.gif) top no-repeat;
    }
    .linkedin{
      background: url(../img/linkedin.gif) top no-repeat;
    }
   a{
    height: 56px;
    width: 56px;
    display: inline-block;
    margin: 11px;
    span{
      display: none;
    }
    &:hover{
      background-position: bottom;
    }
    }
  }
}

.flex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}
.col-2{
  min-width: 200px;
  flex: 1 200;
  align-self: flex-start;
}
.col-1{
  width: 100%;
  display: block;
}
.stretch{
  align-self: stretch;
}
.v-center{
  align-self: center;
  text-align: center
}
.grey{
  background: #E1E1E1;
  padding: 30px;
}
.white{
  padding: 30px;
}
.white-col{
  background: #fff;
  padding: 30px;
  margin: 10px;
}
.inner{
  h1{
    color: #2e3030;
    font-size: 30px;
    margin: 30px 0;
  }
  h2{
    color: #2e3030;
    font-size: 18px;
    margin: 30px 0;
  }
  h3{
    color: #E10022;
    font-size: 16px;
    margin: 20px 0 0 0;
  }
}
@media screen and (max-width: 450px){
  .container .tab.gr{
    display: none;
  }
}
